import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Img from "gatsby-image";
import {
  makeStyles
} from '@material-ui/styles';
import {
  Typography, Button, useMediaQuery
} from '@material-ui/core';
import {
  ArrowForward as ArrowRight
} from '@material-ui/icons';
import {
  PlayArrowRounded as Play
} from '@material-ui/icons';

import ProjectForm from '../projectStart/projectStartForm';
import WatchVideo from './hero/video';
import {
  update,
} from '../../state/project/ducks';
import theme from '../../themes/baseTheme';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  heroContent: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0
  },
  heroText: {
    color: 'white',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      fontSize: 42
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 34
    }
  },
  heroSubTextWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  heroSubText: {
    color: 'white',
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  heroTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: 700,
    padding: theme.spacing(3)
  },
  btn: {
    fontWeight: 900,
    margin: theme.spacing(),
    minWidth: 180,
    borderRadius: theme.spacing(5)
  },
  ctaContainer: {
    display: 'flex', marginTop: theme.spacing(),
    flexWrap: 'wrap',
    gap: `${theme.spacing()}px`,
    justifyContent: 'center'
  },
  bottomBanner: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: 0
  },
  credLogos: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    maxWidth: 900,
    marginBottom: theme.spacing(),
    flexWrap: 'wrap'
  },
  credLogo: {
    margin: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      height: 20
    }
  }
}))
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const HeroComponent = ({
  update
}: {
  update: (keypath: string[], value: any) => void;
}) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      placeholderImageLarge: file(relativePath: { eq: "home/aa_home.jpeg" }) {
        childImageSharp {
          fluid(cropFocus: SOUTHWEST, maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImageSmall: file(relativePath: { eq: "home/aa_home.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bbbLogoLg: file(relativePath: { eq: "layout/bbb.png"}) {
        childImageSharp {
          fixed(height: 40, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bbbLogoSm: file(relativePath: { eq: "layout/bbb.png"}) {
        childImageSharp {
          fixed(height: 27, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoSixteenLg: file(relativePath: { eq: "layout/TopContractor2016.png"}) {
        childImageSharp {
          fixed(height: 40, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoSixteenSm: file(relativePath: { eq: "layout/TopContractor2016.png"}) {
        childImageSharp {
          fixed(height: 27, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoSeventeenLg: file(relativePath: { eq: "layout/TopContractor2017.png"}) {
        childImageSharp {
          fixed(height: 40, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoSeventeenSm: file(relativePath: { eq: "layout/TopContractor2017.png"}) {
        childImageSharp {
          fixed(height: 27, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoEighteenLg: file(relativePath: { eq: "layout/TopContractor2018.png"}) {
        childImageSharp {
          fixed(height: 40, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoEighteenSm: file(relativePath: { eq: "layout/TopContractor2018.png"}) {
        childImageSharp {
          fixed(height: 27, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoNineteenLg: file(relativePath: { eq: "layout/TopContractor2019.png"}) {
        childImageSharp {
          fixed(height: 40, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoNineteenSm: file(relativePath: { eq: "layout/TopContractor2019.png"}) {
        childImageSharp {
          fixed(height: 27, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoTwentyLg: file(relativePath: { eq: "layout/TopContractor2020.png"}) {
        childImageSharp {
          fixed(height: 40, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoTwentySm: file(relativePath: { eq: "layout/TopContractor2020.png"}) {
        childImageSharp {
          fixed(height: 27, grayscale: true) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const credLogosLg = [
    'bbbLogoLg',
    'topLogoSixteenLg',
    'topLogoSeventeenLg',
    'topLogoEighteenLg',
    'topLogoNineteenLg',
    'topLogoTwentyLg',
    // 'topLogoSeventeenLg',
    // 'topLogoSeventeenLg',
  ];
  const credLogosSm = [
    'bbbLogoSm',
    'topLogoSixteenSm',
    'topLogoSeventeenSm',
    'topLogoEighteenSm',
    'topLogoNineteenSm',
    'topLogoTwentySm',
  ];
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const credLogos = screenIsSmall ? credLogosSm : credLogosLg;
  const [video, setVideo] = React.useState({open: false});
  const openProject = () => update(['form', 'currentQuestion'], 'projectType');
  return <div className={classes.root}>
    <Img imgStyle={{
      marginBottom: 0
    }} style={{
      height: '100vh',
      filter: 'brightness(50%)',
      WebKitFilter: 'brightness(50%)'
    }} fluid={data.placeholderImageLarge.childImageSharp.fluid} />
    <div className={classes.heroContent}>
      <div className={classes.heroTextWrapper}>
      <Typography align='center' className={classes.heroText} variant='h1'>Page Not Found</Typography>
        <div className={classes.heroSubTextWrapper}>

          <Typography align='center' className={classes.heroSubText} variant='h2'>Oops! We couldn't find the page you're looking for.</Typography>

        </div>
      </div>
    </div>
  </div>
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({
            update
        }, dispatch)
    };
};

const Hero = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeroComponent);

export default Hero;
